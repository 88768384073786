import { Impression } from '../common/models/pathfinder/impression.model';
import { Lead } from '../common/models/pathfinder/lead.model';
import { Offer } from '../common/models/pathfinder/offer.model';
import { ParameterRecord } from '../common/models/pathfinder/parameter-record.model';
import { EmailResponse, EmailResponseStatus } from '../common/models/pathfinder/email-response.model';
import { FetchService } from './fetch.service';
import { PendingImpression } from '../common/models/pathfinder/pending-impression.model';

// get domain
let domain = window.location.hostname.split('.').slice(window.location.hostname.endsWith('.com.br') ? -3 : -2).join('.');

// Special Case Overrides
if (domain == 'familia.com.br') {
    // Portugese Family site will use Spanish Family domain
    domain = 'familias.com';
} else if (domain == 'notion-staging.com' || domain == 'bibleminute.co') {
    // BibleMinute will just use Beliefnet domain
    domain = 'beliefnet.com';
}

// get endpoint url base (familia.com.br needs to pull from familias.com)
const endpointBase = `https://cr.${domain}/public/api/v2.0`;

// handle dev environment
// if (process.env.NODE_ENV == 'development') {
//     endpointBase = 'http://localhost:5000/public/api';
// }

// get user token endpoint url base
const postupEndpointBase = `${endpointBase}/postup`;
// get lead endpoint url base
const leadEndpointBase = `${endpointBase}/leads`;
// get params endpoint url base
const paramsEndpointBase = `${endpointBase}/params`;
// get sesssions endpoint url base
const sessionsEndpointBase = `${endpointBase}/sessions`;
// get instance of fetch service to make api calls
const api = new FetchService();

/**
 * Create Lead Record
 * @param lead Lead record we want to create
 * @returns Lead record that was created
 */
export const createLead = async (lead: Lead): Promise<Lead | null> => {
    try {
        return (await api.post<Lead>(`${leadEndpointBase}`, lead)).data;
    } catch(error) {
        console.error('Failed to create lead. ', error);
    }

    return null;
};

/**
 * Get CoReg Offers for Lead
 * @param leadId Id of lead  record the offers are for
 * @param numberOfOffers Number of offers we want
 * @returns Offer records requested
 */
export const getCoRegOffers = async (leadId: number, numberOfOffers: number): Promise<Offer[]> => {
    try {
        return (await api.get<Offer[]>(`${endpointBase}/leads/${leadId}/coreg-offers?count=${numberOfOffers}`)).data ?? [];
    } catch(error) {
        console.error('Failed to get offers for lead. ', error);
    }

    return [];
}

/**
 * Create Impression Records for Lead
 * @param leadId Id of lead record the impressions apply to
 * @param impressions Impression records we want to create
 * @returns Impression Records
 */
export const createImpression = async (leadId: number, impressions: Impression[]): Promise<Impression[]> => {
    try {
        return (await api.post<Impression[]>(`${endpointBase}/leads/${leadId}/impressions`, impressions)).data ?? [];
    } catch(error) {
        console.error('Failed to save impressions for lead. ', error);
    }

    return [];
}

export const createPendingImpression = async (offerId: number, pendingImpression: PendingImpression): Promise<PendingImpression | boolean> => {
    try {
        const response = await api.post<PendingImpression>(`${endpointBase}/offers/${offerId}/pending-impressions`, pendingImpression);
        return response.ok && !!response.data ? response.data : false;
    } catch(error) {
        console.error('Failed to save pending impression for offer. ', error);
    }

    return false;
}

/**
 * Get global parameters
 * @returns Global Parameter Records
 */
export const getParameters = async (): Promise<ParameterRecord[]> => {
    try {
        return (await api.get<ParameterRecord[]>(`${paramsEndpointBase}`)).data ?? [];
    } catch(error) {
        console.error('Failed to get parameters. ', error);
    }

    return [];
}

/**
 * Get email address based on PostUp Recipient
 * @param recipId PostUp Recip Id
 * @param siteId PostUp Site Id. this is optional. Fallback is to check PostUp site based on request domain.
 * @returns Email Address of PostUp Recipient
 */
export const getEmailByRecipId = async (recipId: string | number, siteId: string) => {
    try {
        const endpoint = `${postupEndpointBase}/recip/${recipId}/email` + (siteId ? `?siteId=${siteId}` : '');
        const response = await api.get<EmailResponse>(endpoint);

        if (response.ok && response.data?.result == EmailResponseStatus.SUCCESS) {
            return response.data.email;
        }

        if (response.ok && response.data?.result == EmailResponseStatus.ERROR) {
            console.error('Failed to get email by recip id. ', response.data.message);
        } else {
            console.error('Failed to get email by recip id');
        }
    } catch(error) {
        console.error('Failed to get email by recip id. ', error);
    }

    return '';
}

/**
 * Get email address based on RSID
 * @param rsid Session Id for which we want to get the associated email
 * @returns Email Address of lead attached to session id
 */
export const getEmailByRSID = async (rsid: string) => {
    try {
        const endpoint = `${sessionsEndpointBase}/${rsid}/email`;
        const response = await api.get<EmailResponse>(endpoint);

        if (response.ok && response.data?.result == EmailResponseStatus.SUCCESS) {
            return response.data.email;
        }

        if (response.ok && response.data?.result == EmailResponseStatus.ERROR) {
            console.error('Failed to get email by rsid. ', response.data.message);
        } else {
            console.error('Failed to get email by rsid');
        }
    } catch(error) {
        console.error('Failed to get email by rsid. ', error);
    }
    return '';
}