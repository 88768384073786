import { FetchResponse } from '../common/models/app/fetch-response.model';

const RequestTypes = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

export class FetchService {
    public get<T>(endpoint: string): Promise<FetchResponse<T>> {
        return new Promise<FetchResponse<T>>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(RequestTypes.GET, endpoint, true);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve({
                            data: JSON.parse(xhr.responseText) as T,
                            ok: true,
                            statusText: xhr.statusText
                        } as FetchResponse<T>);
                    } else {
                        resolve({
                            data: null,
                            ok: false,
                            statusText: xhr.statusText
                        } as FetchResponse<T>);
                    }
                }
            }

            xhr.onerror = () => {
                reject(new Error(`Post failed to ${endpoint} with status: ${xhr.statusText}`));
            }

            xhr.send(null);
        });
    }

    public post<T>(endpoint: string, data: any, contentType = 'application/json'): Promise<FetchResponse<T>> {
        return new Promise<FetchResponse<T>>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(RequestTypes.POST, endpoint, true);

            xhr.setRequestHeader('Content-Type', contentType);

            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        resolve({
                            data: JSON.parse(xhr.responseText) as T,
                            ok: true,
                            statusText: xhr.statusText
                        } as FetchResponse<T>);
                    } else {
                        resolve({
                            data: null,
                            ok: false,
                            statusText: xhr.statusText
                        } as FetchResponse<T>);
                    }
                }
            }

            xhr.onerror = () => {
                reject(new Error(`Post failed to ${endpoint} with status: ${xhr.statusText}`));
            }

            // if content type is json, stringify the payload automatically
            // otherwise, just pass through what was provided
            xhr.send(contentType == 'application/json' ? JSON.stringify(data) : data);
        });
    }
}
