import { PiniaPluginContext, defineStore } from 'pinia';
import { ref } from 'vue';
import useQueryParameter from '../composables/query-parameter.composable';
import useRadUtils from '../composables/rad-utils.composable';

const sessionIdQueryParameterName = 'rsid';
const sessionExpirationMinutes = 15;
const brandSegment = 'P';

export const useSessionStore = defineStore('session', 
    () => {
        const rsid = ref('');
        const expiration = ref(new Date());

        const init = () => {
            if (rsid.value) {
                return;
            }

            const radUtils = useRadUtils();
            const paramRSID = useQueryParameter(sessionIdQueryParameterName);
            if (paramRSID.value) {
                rsid.value = paramRSID.value;
                expiration.value = radUtils.addMinutes(new Date(), sessionExpirationMinutes);
            } else {
                const numericSegment = Math.floor(100 + Math.random() * 900);
                const alphanumericSegment = radUtils.randomString(12);
                rsid.value = `${ brandSegment }${ numericSegment }${ alphanumericSegment }`;
                expiration.value = radUtils.addMinutes(new Date(), sessionExpirationMinutes);
            }
        }

        return {
            rsid,
            expiration,
            init
        }
    },
    {
        persist: {
            storage: sessionStorage,
            afterRestore: (context: PiniaPluginContext) => {
                // fix expiration date
                const rsid = context.store.$state.rsid;
                let expiration = context.store.$state.expiration;
                if (expiration) {
                    expiration = new Date(expiration);
                    context.store.$patch({ expiration } as any);
                }

                // if we found something in storage and it's expired, clear it out
                if (!rsid || !expiration || expiration.getTime() < Date.now()) {
                    context.store.$patch({
                        rsid: '',
                        expiration: new Date()
                    } as any);
                }
            }
        }
    });
