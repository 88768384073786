import { VueLogger } from 'vue-logger-plugin';

export class DeviceService {
    private logger: VueLogger;
    private deviceScreenWidth = window.innerWidth;

    constructor(logger: VueLogger) {
        this.logger = logger;

        this.logger.debug(`Device Screen Width Detected: ${this.deviceScreenWidth}`);
    }

    private screenMaxWidth = {
        Large: 1300,
        Medium: 1000,
        Small: 768,
        XSmall: 480
    };

    public isPhone(): boolean {
        return this.deviceScreenWidth < this.screenMaxWidth.Small;
    }

    public isTablet(): boolean {
        return this.deviceScreenWidth < this.screenMaxWidth.Large && !this.isPhone();
    }

    public isDesktop(): boolean {
        return !this.isPhone() && !this.isTablet();
    }

    public isMobile(): boolean {
        return this.isPhone() || this.isTablet();
    }

    public hasTouchSupport(): boolean {
        return 'ontouchend' in document;
    }
}
