import { ref } from 'vue';

export default function useQueryParameter(key: string) {
    const param = ref('');

    const url = new URL(window.location.href);
    if (url.searchParams.has(key)) {
        param.value = url.searchParams.get(key) ?? '';
    }

    return param;
}
