import { VueLogger } from 'vue-logger-plugin';

export class GAService {
    private logger: VueLogger;
    constructor(logger: VueLogger) {
        this.logger = logger;
    }

    public setDimension(index: string, value: any): void {
        this.logger.debug('setting dimension values', index, value);
        window.ga('set', 'dimension' + index, value);
    }

    public fireEvent(category: string, action: string, label: string, nonInteraction: boolean): void {
        this.logger.debug('firing event', category, action, label, nonInteraction);
        window.ga('send', 'event', category, action, label, {
            nonInteraction: nonInteraction
        });
    }
}
