import { FetchResponse } from '../common/models/app/fetch-response.model';
import { FetchService } from './fetch.service';
import { VueLogger } from 'vue-logger-plugin';
import { IpApiResponse } from '../common/models/app/ip-api-response.model';

const cookieLawZoneCountries = ['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','EL','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','UK','EU'];
const cookieLawZoneStates = ['California', 'Nevada'];
const cookieLawZoneCacheKey = 'rad-clz-check';
const geoLocApiEndpoint = 'https://pro.ip-api.com/json/?fields=status,message,countryCode,regionName';
const apiKey = window.rad?.ads?.preload?.pageConfig?.ipApiKey;

export class LocationService {
    private logger: VueLogger;
    private fetchService: FetchService;

    constructor(logger: VueLogger, fetchService: FetchService) {
        this.logger = logger;
        this.fetchService = fetchService;
    }

    public isCookieLawZone = async () => {
        // check cache
        const cache = sessionStorage.getItem(cookieLawZoneCacheKey);
        if (cache) {
            return cache == 'true';
        }

        if (!apiKey) {
            console.error('IP-API API Key not Defined. Unable to check locale.');
            return false;
        }

        let response: FetchResponse<IpApiResponse>;
        try {
            // make api call
            response = await this.fetchService.get<IpApiResponse>(`${geoLocApiEndpoint}&key=${apiKey}`);
        } catch(error:any) {
            console.error(`Geoloc API Call Failed, likely with a network error: ${error || 'unknown'}`);
            return false;
        }
        // ensure we got a good api response, default to false
        if (!response.ok || response.data == null) {
            console.error(`Something went wrong checking geoloc api: ${response.statusText}`);
            return false;
        }

        // pull out result
        const result = response.data;

        // check result
        const isCookieLawZone = result.countryCode == 'US' && cookieLawZoneStates.includes(result.regionName)
            || cookieLawZoneCountries.includes(result.countryCode);

        // store in cache
        sessionStorage.setItem(cookieLawZoneCacheKey, isCookieLawZone.toString());

        console.debug(`Returning Response on Cookie Law Zone: ${isCookieLawZone.toString()}`);
        
        // return
        return isCookieLawZone;
    }
}
