export const UserTokenResponseStatus = {
    SUCCESS: 'Success',
    ERROR: 'Error'
}

export interface UserTokenResponse {
    result: string;
    message: string;
    token: string;
    uid2: UID2;
}

export interface UserTokenRefreshResponse {
    result: string;
    uid2: UID2;
}

export interface UID2 {
    body: UID2_Body;
    status: string;
}

export interface UID2_Body {
    advertising_token: string;
    refresh_token: string;
    refresh_response_key: string;
    refresh_from: string;
    identity_expires: string;
    refresh_expires: string;
}