import { EmailResponse, EmailResponseStatus } from '../common/models/pathfinder/email-response.model';
import { FetchService } from './fetch.service';

const domain = window.location.hostname.split('.').slice(window.location.hostname.endsWith('.com.br') ? -3 : -2).join('.');

// when in use on BibleMinute Staging, we'll use the staging domain. Otherwise we'll use Production domain.
const endpointBase = domain == 'notion-staging.com'
    ? 'https://bibleminute.notion-staging.com/api'
    : 'https://www.bibleminute.co/api';

// get instance of fetch service to make api calls
const api = new FetchService();

export const getEmailByVID = async (vid: string) => {
    try {
        const response = await api.get<EmailResponse>(`${endpointBase}/get-email.php?vid=${vid}`);

        if (response.ok && response.data?.result == EmailResponseStatus.SUCCESS) {
            return response.data.email;
        }

        if (response.ok && response.data?.result == EmailResponseStatus.ERROR) {
            console.error('Failed to get email by vid. ', response.data.message);
        } else {
            console.error('Failed to get email by vid');
        }
    } catch(error:any) {
        console.error('Failed to get email by vid. ', error);
    }

    return '';
}