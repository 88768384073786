export default function useRadUtils() {
    const addMinutes = (date: Date, minutes: number) => {
        return new Date(date.getTime() + minutes * 60 * 1000);
    };

    const randomString = (length: number, chars = 'abcdefghijklmnopqrstuvwxyz0123456789'): string => {
        let result = '';
        for(let i = length; i > 0; --i) {
            result += chars[ Math.floor(Math.random() * chars.length) ];
        }
        return result;
    };

    const paramAsSingleString = (val: string | string[], defaultValue = ''): string => {
        if(val) {
            if(Array.isArray(val) && val.length > 0) {
                return val[ 0 ];
            }

            if(typeof val == 'string') {
                return val;
            }
        }

        return defaultValue;
    };

    return {
        addMinutes,
        randomString,
        paramAsSingleString
    };
}
