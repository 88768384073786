import { NewsletterSubscriptionResponse } from '../common/models/newslettermanage/newsletter-subscription-response.model';
import { VueLogger } from 'vue-logger-plugin/dist/types/logger';
import { FetchService } from './fetch.service';
import { useIdentityStore } from '../stores/identity-store';

const endpointBase = 'https://www.patheos.com/ptapi/newsletter';

export class NewsletterManageService {
    private logger: VueLogger;
    private fetchService: FetchService;

    constructor(logger: VueLogger, fetchService: FetchService) {
        this.logger = logger;
        this.fetchService = fetchService
    }

    /**
     * Subscribe user to newsletterssubscribe
     * @param subscription subscription object to be sent to server
     */
    async subscribe(
        email: string,
        name: string,
        sourceDesc: string,
        zip: string,
        newsletters: number[],
        partners: boolean,
        rsid: string
    ): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug('Posting subscription request to server');

        try {
            const data = {
                email,
                name,
                zip,
                newsletter: newsletters,
                sourceDesc,
                partners,
                rsid
            }

            // after we capture the email, update the identity store
            if (email) {
                const identity = useIdentityStore();
                identity.registerEmail(email).then(() => 'Identity Email Registration Complete');
            }

            const result = await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/subscribe`,
                data
            );

            return result.data;
        } catch(error) {
            this.logger.error('newsletter subscription failed: ', error);
        }

        return null;
    }

    /**
     * get back subscriptions
     * @param email email address
     */
    async getSubscriptions(email: string): Promise<NewsletterSubscriptionResponse |null> {
        this.logger.debug('Post get email subscription request to server');

        try {
            const data = {
                email
            };

            const result = await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/getsubscriptions`,
                data
            );
            return result.data;
        } catch(error) {
            this.logger.error('newsletter get subscriptions failed: ', error);
        }

        return null;
    }

    /**
     * get back newsletters based on numbers
     * @param newsletters list of newsletter ids
     */
    async updateEmailSubscription(
        Email: string,
        Newsletter: number[]
    ): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug('Posting update email subscription request to server');

        try {
            const data = {
                Email,
                Newsletter: Newsletter
            };

            const result = await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/updatesubscription`,
                data
            );
            return result.data;
        } catch(error) {
            this.logger.error('newsletter update subscription failed: ', error);
        }

        return null;
    }
}
