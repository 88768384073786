import { VueLogger } from 'vue-logger-plugin';

export class GTMService {
    private logger: VueLogger;
    constructor(logger: VueLogger) {
        this.logger = logger;

        window.dataLayer = window.dataLayer || [];
        if (!window.gtag) {
            window.gtag = (n: any, e: any, p: any) => { window.dataLayer.push(n, e, p); };
        }
    }

    public registerEvent(event: string, customProps: any = {}): void {
        // for ga4
        window.gtag('event', event, customProps);

        // for universal analytics
        window.dataLayer.push({
            event,
            ...customProps
        });
    }
}
